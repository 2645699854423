<template>
  <div>
    <b-card>
      <div>
        <validation-observer ref="validator" mode="eager">
          <b-form>
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group label="Analytics key" label-for="analyticsKey">
                  <validation-provider #default="{ errors }" name="analyticsKey" rules="required|alpha-num">
                    <b-form-input
                      id="analyticsKey"
                      type="text"
                      placeholder="key"
                      v-model="form.key"
                      :state="errors.length ? false : null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group label="Analytics value" label-for="analyticsValue">
                  <validation-provider #default="{ errors }" name="analyticsValue" rules="required|alpha-num">
                    <b-form-input
                      id="analyticsValue"
                      type="text"
                      placeholder="value"
                      v-model="form.value"
                      :state="errors.length ? false : null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Action Buttons -->
            <b-button variant="primary" type="submit" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
              {{ keyId ? 'Update' : 'Create' }}
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
// eslint-disable-next-line
import { required, alphaDash } from '@validations'
export default {
  name: 'AnalyticsEdit',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      keyId: null,

      form: {
        key: '',
        value: '',
      },
    }
  },

  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchTrackableLink: 'trackableLinks/fetchTrackableLink',
      editTrackableLink: 'trackableLinks/editTrackableLink',
      createTrackableLink: 'trackableLinks/createTrackableLink',
    }),

    initState() {
      this.registerValidator(this.$refs.validator)

      if (!this.$route.params.id) return

      this.keyId = parseInt(this.$route.params.id, 10)
      this.fetchTrackableLink(this.keyId)
        .then(response => {
          this.form = {
            key: response.data.key || '',
            value: response.data.value || '',
          }
        })
        .catch(this.checkErrors)
    },

    async save() {
      const isValid = await this.checkIsValidForm()
      if (this.requestInProgress || !isValid) return

      if (this.keyId) return this.update()

      this.create()
    },

    create() {
      this.waitRequest(() => {
        return this.createTrackableLink(this.form)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Link is added',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },

    update() {
      this.waitRequest(() => {
        return this.editTrackableLink({ id: this.keyId, form: this.form })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Analytics keys is updated',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })

            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
